import React, { useEffect } from 'react';
import Navbar from '../components/NavBar/Navbar';
import CompanyOverview from '../components/Empresa/CompanyOverview';
import ConstructionProcess from '../components/Empresa/ConstructionProcess';
import GetInTouch from '../components/Empresa/GetInTouch';
import '../components/Empresa/style/EmpresaScreen.css';
import { Helmet } from 'react-helmet';

const EmpresaScreen = () => {
  useEffect(() => {
    // Al cargar el componente, desplaza la pantalla al inicio
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
        {/* Metaetiquetas SEO para la página */}
        <Helmet>
        <title>Piscinas Jiménez Ureña - Construcción y Mantenimiento de Piscinas en Granada, Málaga y Jaén</title>
        <meta
          name="description"
          content="Piscinas Jiménez Ureña, expertos en construcción y mantenimiento de piscinas personalizadas en Granada, Málaga y Jaén. Más de 30 años de experiencia garantizando calidad y diseño único."
        />
        <meta
          name="keywords"
          content="construcción de piscinas Granada, mantenimiento de piscinas Málaga, diseño de piscinas personalizadas Jaén, reparación de piscinas, piscinas de lujo Andalucía"
        />
        <meta name="author" content="Piscinas Jiménez Ureña" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <meta property="og:title" content="Piscinas Jiménez Ureña - Construcción y Mantenimiento de Piscinas en Granada, Málaga y Jaén" />
        <meta
          property="og:description"
          content="Somos especialistas en diseño, construcción y mantenimiento de piscinas en Granada, Málaga y Jaén. Solicita tu presupuesto personalizado hoy mismo."
        />
        <meta property="og:image" content="https://www.piscinasjurena.es/assets/portada-piscinas.jpg" />
        <meta property="og:url" content="https://www.piscinasjurena.es" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="es_ES" />
        <meta property="og:site_name" content="Piscinas Jiménez Ureña" />

        <link rel="canonical" href="https://www.piscinasjurena.es" />
      </Helmet>

      <Navbar onScroll={(sectionId) => document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' })} />
      <main className="empresa-page">
        <br></br>
        <br></br>
        <br></br>
        <CompanyOverview />
        <ConstructionProcess />
        <GetInTouch />
      </main>
    </>
  );
};

export default EmpresaScreen;
