import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/NavBar/Navbar';
import AboutSection from '../components/Home/AboutSection';
import GetInTouch from '../components/Home/GetInTouch';
import HeaderSection from '../components/Home/HeaderSection';
import '../components/Home/style/HomeScreen.css';

const HomeScreen = () => {
  useEffect(() => {
    // Al cargar el componente, desplaza la pantalla al inicio
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      {/* Metaetiquetas SEO para la página */}
      <Helmet>
        <title>Piscinas Jiménez Ureña - Construcción y Mantenimiento de Piscinas en Granada, Málaga y Jaén</title>
        <meta
          name="description"
          content="Piscinas Jiménez Ureña, expertos en construcción y mantenimiento de piscinas personalizadas en Granada, Málaga y Jaén. Más de 30 años de experiencia garantizando calidad y diseño único."
        />
        <meta
          name="keywords"
          content="construcción de piscinas Granada, mantenimiento de piscinas Málaga, diseño de piscinas personalizadas Jaén, reparación de piscinas, piscinas de lujo Andalucía"
        />
        <meta name="author" content="Piscinas Jiménez Ureña" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <meta property="og:title" content="Piscinas Jiménez Ureña - Construcción y Mantenimiento de Piscinas en Granada, Málaga y Jaén" />
        <meta
          property="og:description"
          content="Somos especialistas en diseño, construcción y mantenimiento de piscinas en Granada, Málaga y Jaén. Solicita tu presupuesto personalizado hoy mismo."
        />
        <meta property="og:image" content="https://www.piscinasjurena.es/assets/portada-piscinas.jpg" />
        <meta property="og:url" content="https://www.piscinasjurena.es" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="es_ES" />
        <meta property="og:site_name" content="Piscinas Jiménez Ureña" />

        <link rel="canonical" href="https://www.piscinasjimenezurena.es/" />
      </Helmet>


      {/* Navbar reutilizable */}
      <Navbar />

      <main>
        <div id="header-section" className="container-fluid p-0">
          <HeaderSection />
        </div>
        <div id="about-section" className="container-fluid p-0" aria-labelledby="about-title">
          <AboutSection />
        </div>
        <div id="contact-section" className="container-fluid p-0" aria-labelledby="contact-title">
          <GetInTouch />
        </div>
      </main>
    </>
  );
};

export default HomeScreen;
