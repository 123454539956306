import React, { useState, useRef, useEffect } from 'react';
import './style/HeaderSection.css';
import videoUno from '../../assets/videos/videosPresentacion/videoPiscinaUno.mp4';
import videoDos from '../../assets/videos/videosPresentacion/videoPiscinaDos.mp4';
import videoTres from '../../assets/videos/videosPresentacion/videoPiscinaTres.mp4';
import videoCuatro from '../../assets/videos/videosPresentacion/videoPiscinaCuatro.mp4';
import videoCinco from '../../assets/videos/videosPresentacion/videoPiscinaCinco.mp4';
import videoSeis from '../../assets/videos/videosPresentacion/videoPiscinaSeis.mp4';

const HeaderSection = () => {
  const videos = [videoCuatro, videoTres, videoSeis, videoCinco, videoUno, videoDos];
  const [activeVideo, setActiveVideo] = useState(() => parseInt(sessionStorage.getItem('activeVideo'), 10) || 0);
  const videoRef = useRef(null);

  const handleNextVideo = () => setActiveVideo((prevVideo) => (prevVideo + 1) % videos.length);
  const handlePrevVideo = () => setActiveVideo((prevVideo) => (prevVideo - 1 + videos.length) % videos.length);

  useEffect(() => {
    sessionStorage.setItem('activeVideo', activeVideo);

    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.load();
      videoElement.play().catch((err) => {
        console.error('Error al reproducir el video:', err);
      });
    }
  }, [activeVideo]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      const videoElement = videoRef.current;
      if (videoElement) {
        if (document.visibilityState === 'hidden') {
          videoElement.pause();
        } else {
          videoElement.play().catch((err) => console.error('Error al reproducir el video:', err));
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  useEffect(() => {
    const isMobile = window.innerWidth <= 576; // Detecta si es un dispositivo móvil
    if (isMobile) {
      const overlayElement = document.querySelector(".overlay");
      if (overlayElement) {
        const spacer = document.createElement("div"); // Crear un div para el espacio
        spacer.style.height = "0.5px"; // Ajustar la altura deseada (por ejemplo, la mitad del tamaño estándar)
        overlayElement.parentNode.insertBefore(spacer, overlayElement); // Insertar el div antes del <h1>
      }
    }
  }, []);

  return (
    <header className="header-section fade-in">
      <div className="overlay"></div>
      <div className="content-wrapper d-flex flex-column flex-md-row align-items-center">
        {/* Text Column */}
        <div className="text-column bounce-in">
          <div className="header-subtitle">
            <h1 className="header-title">
              Construyendo Piscinas de Ensueño con Calidad y Excelencia
            </h1>
            <button
              className="cta-button"
              aria-label="Solicitar presupuesto"
              onClick={() => {
                document.getElementById('contact-section').scrollIntoView({ behavior: 'smooth' });
              }}
            >
              Solicitar Presupuesto
            </button>
          </div>
        </div>

        {/* Video Column */}
        <div className="video-column">
          <div className="video-container">
            <video
              ref={videoRef}
              src={videos[activeVideo]}
              loop
              muted
              className="header-video"
              aria-label={`Presentación de Piscinas - Video ${activeVideo + 1}`}
              preload="metadata"
              playsInline
            />
            <div className="video-controls">
              <button
                className="video-control prev"
                onClick={handlePrevVideo}
                aria-label="Video anterior"
              >
                &#10094;
              </button>
              <button
                className="video-control next"
                onClick={handleNextVideo}
                aria-label="Video siguiente"
              >
                &#10095;
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderSection;
