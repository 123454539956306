import React, { useState, useEffect, useRef } from "react";
import Navbar from "../../NavBar/Navbar";
import Modal from "react-modal";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Zoom } from "swiper/modules";
import DOMPurify from "dompurify";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import "./style/HormigonSection.css";

import privatePoolHormigonImage1 from "../../../assets/images/piscinas/private-pool/piscinas-hormigon/descarga.png";
import privatePoolHormigonImage2 from "../../../assets/images/piscinas/private-pool/piscinas-hormigon/descargaOne.png";
import privatePoolHormigonImage3 from "../../../assets/images/piscinas/private-pool/piscinas-hormigon/descargaTwo.png";
import privatePoolHormigonImage4 from "../../../assets/images/piscinas/private-pool/piscinas-hormigon/descargaTwo copy.png";
import privatePoolHormigonImage5 from "../../../assets/images/piscinas/private-pool/piscinas-hormigon/descargaTwo copy 2.png";
import privatePoolHormigonImage6 from "../../../assets/images/piscinas/private-pool/piscinas-hormigon/descargaTwo copy 3.png";
import privatePoolHormigonImage7 from "../../../assets/images/piscinas/private-pool/piscinas-hormigon/descargaTwo copy 4.png";

const images = [
  privatePoolHormigonImage1,
  privatePoolHormigonImage2,
  privatePoolHormigonImage3,
  privatePoolHormigonImage4,
  privatePoolHormigonImage5,
  privatePoolHormigonImage6,
  privatePoolHormigonImage7,
];

const HormigonSection = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [showScrollArrow, setShowScrollArrow] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errors, setErrors] = useState({});
  const textSectionRef = useRef(null);

  const handleImageClick = (index) => {
    setActiveImageIndex(index);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const scrollToGallery = () => {
    document.getElementById("gallery").scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const textSection = textSectionRef.current;
        const textSectionVisible = entries.some(
          (entry) => entry.target === textSection && entry.isIntersecting
        );
        setShowScrollArrow(textSectionVisible);
      },
      { threshold: 0.1 }
    );

    const textSection = textSectionRef.current;
    if (textSection) observer.observe(textSection);

    return () => {
      if (textSection) observer.unobserve(textSection);
    };
  }, []);

  useEffect(() => {
    const isMobile = window.innerWidth <= 576; // Detecta si es un dispositivo móvil
    if (isMobile) {
      const h1Element = document.querySelector(".about-title");
      if (h1Element) {
        const spacer = document.createElement("div"); // Crear un div para el espacio
        spacer.style.height = "0.5px"; // Ajustar la altura deseada (por ejemplo, la mitad del tamaño estándar)
        h1Element.parentNode.insertBefore(spacer, h1Element); // Insertar el div antes del <h1>
      }
    }
  }, []);
  
  const handleChange = (e) => {
    const sanitizedValue = DOMPurify.sanitize(e.target.value.trim());
    setFormData({
      ...formData,
      [e.target.name]: sanitizedValue,
    });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const validateFields = () => {
    const tempErrors = {};

    if (!formData.name.trim()) {
      tempErrors.name = "El nombre es obligatorio.";
    } else if (formData.name.trim().length < 3) {
      tempErrors.name = "El nombre debe tener al menos 3 caracteres.";
    }

    if (!formData.email.trim()) {
      tempErrors.email = "El correo electrónico es obligatorio.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "Por favor, introduce un correo electrónico válido.";
    }

    if (!formData.phone.trim()) {
      tempErrors.phone = "El teléfono es obligatorio.";
    } else if (!/^(\+34\s?)?6\d{8}$/.test(formData.phone)) {
      tempErrors.phone =
        "Por favor, introduce un número de móvil español válido (con o sin prefijo +34).";
    }

    if (!formData.message.trim()) {
      tempErrors.message = "El mensaje es obligatorio.";
    } else if (formData.message.trim().length < 10) {
      tempErrors.message = "El mensaje debe tener al menos 10 caracteres.";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateFields()) return;

    try {
      const response = await fetch("/api/sendEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccessMessage("¡Formulario enviado exitosamente!");
        setFormData({ name: "", email: "", phone: "", message: "" });
        setErrors({});
      } else {
        const errorText = await response.json();
        setErrorMessage(
          `Error: ${errorText.error ||
          "No se pudo enviar el formulario. Por favor, inténtelo más tarde."
          }`
        );
      }
    } catch (error) {
      setErrorMessage(
        "Error al conectar con el servidor. Por favor, inténtelo más tarde."
      );
    }
  };

  return (
    <main className="container-fluid my-5 text-center about-section">
      <Navbar onScroll={(sectionId) => document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" })} />
      <article className="container-fluid px-0">
        <header className="row mx-0">
          <div className="col-md-6 px-0" ref={textSectionRef}>
            <h1 className="about-title text-center">
              Expertos en Piscinas de Hormigón Proyectado en Granada, Málaga y Jaén
            </h1>
            <p className="about-subtitle text-center fw-light mb-5">
              En <strong>Piscinas Jiménez Ureña</strong>, nos especializamos en la <strong>construcción de piscinas de hormigón proyectado</strong>, una técnica que combina <strong>adaptabilidad total</strong>, <strong>durabilidad extrema</strong> y un diseño personalizado para crear espacios únicos que se adaptan a cualquier estilo de vida.
            </p>
            <p className="about-subtitle text-center fw-light mb-5">
              Nuestro método de <strong>hormigón proyectado</strong> asegura <strong>resistencia superior</strong> y <strong>acabados impecables</strong>, perfectos para <strong>entornos residenciales</strong> y <strong>proyectos de alta gama</strong>. Ya sea un diseño moderno o clásico, utilizamos materiales premium y tecnología de vanguardia para lograr resultados excepcionales que garantizan satisfacción y estilo.
            </p>
            <p className="about-subtitle text-center fw-light mb-5">
              ¿Buscas <strong>piscinas de hormigón proyectado en Granada, Málaga o Jaén</strong>? Te ofrecemos soluciones personalizadas que destacan por su calidad y exclusividad. Con más de 20 años de experiencia, transformamos tus ideas en realidad, creando piscinas que reflejan tu visión y elevan el valor de tu hogar.
            </p>
            <p className="about-subtitle text-center fw-light mb-5">
              Contáctanos ahora y descubre por qué <strong>Piscinas Jiménez Ureña</strong> es la mejor opción para la <strong>construcción de piscinas premium</strong>. ¡Haz de tu piscina el espacio perfecto para relajarte y disfrutar con los tuyos!
            </p>


            {showScrollArrow && (
              <div className="scroll-arrow-wrapper" onClick={scrollToGallery}>
                <span className="scroll-text">Ver Fotos</span>
                <span className="scroll-arrow">⬇</span>
              </div>
            )}
          </div>

          <div id="gallery" className="col-md-6">
            <div className="gallery-container">
              {images.map((image, index) => (
                <div key={index} className="gallery-item" onClick={() => handleImageClick(index)}>
                  <img
                    src={image}
                    alt={`Piscina de Hormigón - Imagen ${index + 1}`}
                    className="gallery-image"
                    loading="lazy"
                  />
                  <div className="gallery-overlay">
                    <button className="btn btn-primary">Hacer Zoom</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </header>
        <br></br>
        <br></br>
        <section id="getInTouch">
          <form onSubmit={handleSubmit} className="contact-form">
            <h3 className="form-title">Formulario de Contacto</h3>
            {successMessage && <p className="success-message">{successMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <div className="form-group">
              <label htmlFor="name">Nombre</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Nombre"
                required
              />
              {errors.name && <p className="error-message">{errors.name}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="email">Correo Electrónico</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Correo electrónico"
                required
              />
              {errors.email && <p className="error-message">{errors.email}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="phone">Teléfono</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Teléfono"
                required
              />
              {errors.phone && <p className="error-message">{errors.phone}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="message">Mensaje</label>
              <textarea
                id="message"
                name="message"
                rows="4"
                value={formData.message}
                onChange={handleChange}
                placeholder="Mensaje"
                required
              ></textarea>
              {errors.message && <p className="error-message">{errors.message}</p>}
            </div>
            <button type="submit" className="submit-btn slide-in">Enviar mensaje</button>
          </form>
        </section>
      </article>

      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="modal-overlay"
        contentLabel="Image Gallery"
        ariaHideApp={false}
      >
        <div className="modal-content">
          <Swiper
            initialSlide={activeImageIndex}
            modules={[Navigation, Pagination, Zoom]}
            navigation
            pagination={{ clickable: true }}
            zoom={true}
            spaceBetween={10}
            slidesPerView={1}
            style={{ height: "100%", width: "100%" }}
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="swiper-zoom-container" style={{ height: "100%" }}>
                  <img
                    src={image}
                    alt={`Piscina de Hormigón ${index + 1}`}
                    className="swiper-image"
                    loading="lazy"
                    style={{ width: "100%", height: "auto", maxHeight: "80vh", objectFit: "cover" }}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <button onClick={closeModal} className="close-modal-btn">Cerrar</button>
        </div>
      </Modal>
    </main>
  );
};

export default HormigonSection;
