import React from 'react';
import './style/CompanyOverview.css';
import companyImage from '../../assets/images/procesoConstruccion/finalConstruccioneg.webp';

const CompanyOverview = () => {
  return (
    <section className="company-overview">
      <div className="mobile-spacer d-md-none">
        <br />
      </div>
      <div className="company-content">
        <div className="company-text">
          <h3 className="company-title">Sobre nosotros</h3>
          <p className="company-subtitle">
            En <strong>Piscinas Jiménez Ureña</strong>, transformamos cualquier espacio en un auténtico oasis con nuestras <strong>piscinas personalizadas en Granada, Málaga y Jaén</strong>. Somos expertos en <strong>construcción de piscinas exclusivas</strong> que combinan diseño, funcionalidad y la más alta calidad, convirtiendo tu hogar en un lugar único.
          </p>
          <p>
            Con más de <strong>30 años de experiencia</strong>, hemos ganado la confianza de cientos de clientes al ofrecer soluciones adaptadas a sus necesidades y estilos. Nuestro compromiso con la excelencia nos permite superar las expectativas en cada proyecto, asegurando la plena satisfacción de nuestros clientes.
          </p>
          <p>
            En <strong>Piscinas Jiménez Ureña</strong>, nos enorgullecemos de integrar <strong>innovación y tecnología avanzada</strong> en cada diseño. Utilizamos materiales de primera calidad para garantizar piscinas duraderas y estéticamente impecables, que reflejan el estilo de vida y las preferencias de cada cliente.
          </p>
          <p>
            Ofrecemos una amplia gama de servicios, desde <strong>piscinas infinity pool</strong> hasta <strong>piscinas sostenibles</strong>, diseñadas para ser eficientes y respetuosas con el medio ambiente. Ya sea que busques renovar tu piscina actual o construir una nueva, somos la mejor opción en <strong>Granada, Málaga y Jaén</strong>.
          </p>
          <p>
            Descubre por qué somos líderes en el sector de la construcción de piscinas en Andalucía. En <strong>Piscinas Jiménez Ureña</strong>, no solo creamos piscinas; creamos espacios para relajarte, disfrutar y compartir momentos inolvidables con tus seres queridos.
          </p>
          <p>
            <strong>Contáctanos hoy</strong> y transforma tu hogar con una piscina que marcará la diferencia. ¡Hacemos realidad la piscina de tus sueños!
          </p>
        </div>
        <div className="company-image-wrapper">
          <img
            src={companyImage}
            alt="Piscina terminada por Piscinas Jiménez Ureña"
            className="company-image"
          />
        </div>
      </div>
    </section>
  );
};

export default CompanyOverview;
