import React, { useState, useRef, useEffect } from 'react';
import './style/ConstructionProcess.css'; // Importación de los estilos CSS específicos para el componente
import inicioConstruccion from '../../assets/images/procesoConstruccion/inicioConstruccioneg.webp';
import faseIntermedia from '../../assets/images/procesoConstruccion/faseIntermediaeg.webp';
import faseFinalUno from '../../assets/images/procesoConstruccion/faseFinalUnoeg.webp';
import faseFinalDos from '../../assets/images/procesoConstruccion/faseFinalDoseg.webp';

// Componente principal del proceso de construcción
const ConstructionProcess = () => {
  // Definición del array de imágenes finales en la fase final
  const finalImages = [faseFinalUno, faseFinalDos];
  // Estado para controlar la imagen activa en el carrusel
  const [activeImage, setActiveImage] = useState(0);
  // Uso de useRef para acceder a la imagen actual del carrusel directamente
  const imageRef = useRef(null);

  // Función para avanzar a la siguiente imagen en el carrusel, respetando el límite del array (cíclico)
  const handleNextImage = () => setActiveImage((prevImage) => (prevImage + 1) % finalImages.length);
  // Función para retroceder a la imagen anterior en el carrusel, también con lógica cíclica
  const handlePrevImage = () => setActiveImage((prevImage) => (prevImage - 1 + finalImages.length) % finalImages.length);

  // Hook useEffect para actualizar la imagen en el carrusel cada vez que cambia el estado activeImage
  useEffect(() => {
    const imageElement = imageRef.current;
    if (imageElement) {
      imageElement.src = finalImages[activeImage]; // Asignación directa de la fuente de la imagen actual
    }
  }, [activeImage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section className="construction-process container-fluid">
      <h2 className="text-center">Nuestro Proceso de Construcción, Paso a Paso</h2>

      {/* Primera sección: Inicio de la construcción */}
      <div className="row process-content">
        <div className="col-md-6 process-text">
          <h3>Inicio de la Construcción: Planificación Estratégica</h3>
          <br></br>
          <p>
            En <strong>Piscinas Jiménez Ureña</strong>, cada <strong>proyecto de construcción de piscinas</strong> comienza con una
            <strong> planificación estratégica</strong> que combina precisión y experiencia. Nuestro equipo evalúa las
            <strong> condiciones del terreno</strong> para garantizar una construcción sólida y duradera desde el primer paso.
          </p>
          <p>
            Preparamos el área de trabajo con una <strong>base firme y segura</strong>, utilizando materiales de alta calidad que aseguran la
            longevidad de tu piscina. Nuestro compromiso es sentar las bases para un diseño que no solo cumpla con tus expectativas,
            sino que las supere, convirtiendo tu espacio en un lugar exclusivo.
          </p>
          <p>
            Gracias a nuestra experiencia de más de <strong>30 años en Granada, Málaga y Jaén</strong>, ofrecemos soluciones personalizadas
            que combinan <strong>innovación</strong>, calidad y estética, logrando resultados que encantan tanto a nuestros clientes como a sus invitados.
          </p>
        </div>
        <div className="col-md-6 process-images text-center">
          {/* Imagen representativa de la fase inicial */}
          <img
            src={inicioConstruccion}
            alt="Inicio de la construcción de piscinas en Granada, Málaga y Jaén"
            className="img-fluid building-image"
          />
        </div>
      </div>


      {/* Segunda sección: Fase intermedia */}
      <div className="row process-content">
        <div className="col-md-6 process-text">
          <h3>Fase Intermedia: Construcción de la Estructura</h3>
          <br></br>
          <p>
            Durante la <strong>fase intermedia de construcción</strong>, en <strong>Piscinas Jiménez Ureña</strong> damos vida al diseño
            planificado, enfocándonos en cada detalle. Nuestro equipo instala con precisión los <strong>sistemas de fontanería y
              filtración esenciales</strong>, asegurando una funcionalidad impecable y eficiente.
          </p>
          <p>
            La <strong>estructura de la piscina</strong> se erige con materiales de <strong>máxima calidad</strong>, cuidadosamente
            seleccionados por su resistencia, durabilidad y estética superior. Este proceso garantiza que cada piscina sea sólida,
            segura y preparada para durar generaciones.
          </p>
          <p>
            Nuestro enfoque está en la <strong>integración perfecta</strong> de cada componente, respetando el diseño elegido y
            superando las expectativas de nuestros clientes en <strong>Granada, Málaga y Jaén</strong>. Nos aseguramos de que cada detalle
            refleje nuestro compromiso con la calidad y la personalización.
          </p>
          <p>
            En esta etapa, convertimos los planos en una estructura tangible, lista para el siguiente paso hacia la
            <strong> piscina de tus sueños</strong>.
          </p>
        </div>
        <div className="col-md-6 process-images text-center">
          {/* Imagen representativa de la fase intermedia */}
          <img
            src={faseIntermedia}
            alt="Construcción de piscinas en Granada, Málaga y Jaén - Fase intermedia"
            className="img-fluid building-image"
          />
        </div>
      </div>


      {/* Tercera sección: Fase final con carrusel de imágenes */}
      <div className="row process-content">
        <div className="col-md-6 process-text">
          <h3>Fase Final: Detalles y Perfección</h3>
          <br></br>
          <p>
            La <strong>fase final de construcción de piscinas</strong> es donde cada detalle cobra vida. En <strong>Piscinas Jiménez Ureña</strong>,
            nos enfocamos en personalizar cada proyecto para que refleje el estilo único de nuestros clientes en <strong>Granada, Málaga y Jaén</strong>.
          </p>
          <p>
            Aplicamos el revestimiento seleccionado por el cliente, utilizando <strong>materiales de alta calidad</strong> que no solo realzan
            la belleza de la piscina, sino que también garantizan una durabilidad excepcional. Nuestros acabados impecables crean un
            <strong>espacio exterior funcional y estético</strong>, diseñado para maximizar el disfrute.
          </p>
          <p>
            Además, realizamos pruebas técnicas exhaustivas en los sistemas de filtración, fontanería y climatización, asegurando que
            cada componente funcione perfectamente. Entregamos un proyecto que supera los <strong>estándares de calidad</strong> más exigentes,
            ofreciendo a nuestros clientes la tranquilidad de saber que su inversión está en manos de expertos.
          </p>
          <p>
            En esta etapa, convertimos sueños en realidad, entregando una <strong>piscina personalizada</strong> que combina estilo, innovación
            y funcionalidad. Nuestro compromiso es crear espacios que inviten al relax, la diversión y la conexión con tus seres queridos.
          </p>
        </div>
        <div className="col-md-6 process-images text-center">
          <div className="image-carousel">
            {/* Imagen dinámica en el carrusel, actualizada según el índice de activeImage */}
            <img
              ref={imageRef}
              src={finalImages[activeImage]}
              alt={`Fase final de la construcción - Imagen ${activeImage + 1}`}
              className="img-fluid building-image"
            />
            <div className="image-controls">
              {/* Botón de control para imagen anterior en el carrusel */}
              <button className="image-control prev" onClick={handlePrevImage}>&#10094;</button>
              {/* Botón de control para siguiente imagen en el carrusel */}
              <button className="image-control next" onClick={handleNextImage}>&#10095;</button>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
};

export default ConstructionProcess;
