import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeScreen from './pages/HomeScreen';
import HormigonSection from './components/Services/piscinasDeObraPrivada/HormigonSection';
import GetInTouch from './pages/GetInTouch'; // Importa la nueva página
import EmpresaScreen from './pages/EmpresaScreen';  // Importar la nueva página

function App() {
  return (
    <Router>
      <Routes>
        {/* Rutas principales */}
        <Route path="/" element={<HomeScreen />} />
        {/* Rutas específicas de servicios */}
        <Route path="/empresa" element={<EmpresaScreen />} />  {/* Nueva ruta */}
        <Route path="/piscinas-hormigon" element={<HormigonSection />} />
        <Route path="/contacto" element={<GetInTouch />} /> {/* Nueva ruta */}
      </Routes>
    </Router>
  );
}

export default App;
