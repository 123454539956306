import React, { useState } from "react";
import DOMPurify from "dompurify"; // Para sanitización
import "./style/GetInTouch.css";

const GetInTouch = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errors, setErrors] = useState({});

  // Maneja cambios en los inputs y sanitiza datos
  const handleChange = (e) => {
    const sanitizedValue = DOMPurify.sanitize(e.target.value.trim());
    setFormData({
      ...formData,
      [e.target.name]: sanitizedValue,
    });
    setErrors({ ...errors, [e.target.name]: "" }); // Limpia errores del campo específico
  };

  // Validar los campos del formulario
  const validateFields = () => {
    const tempErrors = {};

    if (!formData.name.trim()) {
      tempErrors.name = "El nombre es obligatorio.";
    } else if (formData.name.trim().length < 3) {
      tempErrors.name = "El nombre debe tener al menos 3 caracteres.";
    }

    if (!formData.email.trim()) {
      tempErrors.email = "El correo electrónico es obligatorio.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "Por favor, introduce un correo electrónico válido.";
    }

    if (!formData.phone.trim()) {
      tempErrors.phone = "El teléfono es obligatorio.";
    } else if (!/^(\+34\s?)?6\d{8}$/.test(formData.phone)) {
      tempErrors.phone = "Por favor, introduce un número de móvil español válido (con o sin prefijo +34).";
    }

    if (!formData.message.trim()) {
      tempErrors.message = "El mensaje es obligatorio.";
    } else if (formData.message.trim().length < 10) {
      tempErrors.message = "El mensaje debe tener al menos 10 caracteres.";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0; // Retorna true si no hay errores
  };

  // Maneja el envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateFields()) return; // Detén el envío si hay errores

    try {
      const response = await fetch("/api/sendEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccessMessage("¡Formulario enviado exitosamente!");
        setFormData({ name: "", email: "", phone: "", message: "" }); // Limpia el formulario
        setErrors({});
      } else {
        const errorText = await response.json();
        setErrorMessage(
          `Error: ${
            errorText.error || "No se pudo enviar el formulario. Por favor, inténtelo más tarde."
          }`
        );
      }
    } catch (error) {
      setErrorMessage("Error al conectar con el servidor. Por favor, inténtelo más tarde.");
    }
  };

  return (
    <div className="get-in-touch-content">
      <div className="contact-text fade-in">
        <h3>Ponte en contacto</h3>
        <p>
          En <strong>Piscinas Jiménez Ureña</strong>, estamos aquí para ayudarte a construir la piscina de tus sueños.
          Nos especializamos en ofrecer soluciones personalizadas y de alta calidad.
        </p>
        <div className="contact-details">
          <p>
            Teléfono: <a href="tel:+34659115024">659 115 024</a>
          </p>
          <p>
            Correo electrónico: <a href="mailto:jimenezuresl@gmail.com">jimenezuresl@gmail.com</a>
          </p>
        </div>
        <p>¡No dudes en ponerte en contacto con nosotros para cualquier consulta o cotización!</p>
      </div>
      <div className="contact-form-container fade-in">
        <h3 className="form-title">Formulario de Contacto</h3>
        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-group">
            <label htmlFor="name">Nombre</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Nombre"
              required
            />
            {errors.name && <p className="error-message">{errors.name}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Correo Electrónico</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Correo electrónico"
              required
            />
            {errors.email && <p className="error-message">{errors.email}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="phone">Teléfono</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Teléfono"
              required
            />
            {errors.phone && <p className="error-message">{errors.phone}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="message">Mensaje</label>
            <textarea
              id="message"
              name="message"
              rows="4"
              value={formData.message}
              onChange={handleChange}
              placeholder="Mensaje"
              required
            ></textarea>
            {errors.message && <p className="error-message">{errors.message}</p>}
          </div>
          <button type="submit" className="submit-btn slide-in">Enviar mensaje</button>
        </form>
      </div>
    </div>
  );
};

export default GetInTouch;
