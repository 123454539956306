import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Zoom } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';

import imagenPortadaUno from '../../assets/images/imagenesPortada/imagenPortadaUno.jpeg';
import imagenPortadaDos from '../../assets/images/imagenesPortada/imagenPortadaDos.jpeg';
import imagenPortadaTres from '../../assets/images/imagenesPortada/imagenPortadaTres.jpeg';
import imagenPortadaCuatro from '../../assets/images/imagenesPortada/imagenPortadaCuatro.jpeg';
import imagenPortadaCinco from '../../assets/images/imagenesPortada/imagenPortadaCinco.jpeg';
import imagenPortadaSeis from '../../assets/images/imagenesPortada/imagenPortadaSeis.jpeg';
import imagenPortadaSiete from '../../assets/images/imagenesPortada/imagenPortadaSiete.jpeg';
import imagenPortadaOcho from '../../assets/images/imagenesPortada/imagenPortadaOcho.jpeg';
import imagenPortadaNueve from '../../assets/images/imagenesPortada/imagenPortadaNueve.jpeg';
import imagenPortadaDiez from '../../assets/images/imagenesPortada/imagenPortadaDiez.jpeg';
import imagenPortadaOnce from '../../assets/images/imagenesPortada/imagenPortadaOnce.jpeg';
import imagenPortadaDoce from '../../assets/images/imagenesPortada/imagenPortadaDoce.jpeg';
import imagenPortadaTrece from '../../assets/images/imagenesPortada/imagenPortadaTrece.jpeg';
import imagenPortadaCatorce from '../../assets/images/imagenesPortada/imagenPortadaCatorce.jpeg';
import imagenPortadaQuince from '../../assets/images/imagenesPortada/imagenPortadaQuince.jpeg';

import './style/AboutSection.css';

const AboutSection = () => {
  const images = [
    imagenPortadaDos, imagenPortadaOnce, imagenPortadaSeis, imagenPortadaDiez, imagenPortadaDoce, imagenPortadaQuince, imagenPortadaNueve,
    imagenPortadaTres, imagenPortadaSiete, imagenPortadaCuatro, imagenPortadaOcho,imagenPortadaCatorce,
    imagenPortadaCinco, imagenPortadaTrece, imagenPortadaUno,
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [modalOffsetTop, setModalOffsetTop] = useState(70);
  const [showScrollArrow, setShowScrollArrow] = useState(true);

  const textSectionRef = useRef(null);
  const galleryRef = useRef(null);

  const handleImageClick = (index) => {
    setActiveImageIndex(index);
    setIsOpen(true);
    document.querySelector('.navbar').classList.add('hidden-navbar');
  };

  const closeModal = () => {
    setIsOpen(false);
    document.querySelector('.navbar').classList.remove('hidden-navbar');
  };

  useEffect(() => {
    const updateOffset = () => {
      const navbarHeight = document.querySelector('.navbar')?.offsetHeight || 60;
      setModalOffsetTop(navbarHeight + 5);
    };
    window.addEventListener('resize', updateOffset);
    updateOffset();

    return () => window.removeEventListener('resize', updateOffset);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const textSection = textSectionRef.current;
        const gallerySection = galleryRef.current;

        const textVisible = entries.some(
          (entry) => entry.target === textSection && entry.isIntersecting
        );
        const galleryVisible = entries.some(
          (entry) => entry.target === gallerySection && entry.isIntersecting
        );

        setShowScrollArrow(textVisible && !galleryVisible);
      },
      { threshold: 0.1 }
    );

    const textSection = textSectionRef.current;
    const gallerySection = galleryRef.current;

    if (textSection) observer.observe(textSection);
    if (gallerySection) observer.observe(gallerySection);

    return () => {
      if (textSection) observer.unobserve(textSection);
      if (gallerySection) observer.unobserve(gallerySection);
    };
  }, []);

  const scrollToGallery = () => {
    galleryRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section className="about-section py-5">
      <div className="container-fluid px-0">
        <div className="row mx-0">
          <div className="col-lg-6 col-md-12 pe-lg-5 mb-4 mb-lg-0 text-par" ref={textSectionRef}>
            <h2 className="about-title text-center">Sobre <strong>Piscinas Jiménez Ureña</strong></h2>
            <p className="about-subtitle text-center fw-light mb-5">
              Bienvenido a <strong>Piscinas Jiménez Ureña</strong>, el líder indiscutible en{" "}
              <strong>construcción de piscinas exclusivas</strong> en <strong>Granada</strong>,{" "}
              <strong>Málaga</strong> y <strong>Jaén</strong>. Con una experiencia que supera los{" "}
              <strong>30 años</strong>, somos la elección preferida para quienes buscan{" "}
              <strong>diseño, calidad y personalización</strong> en cada detalle de su{" "}
              <strong>piscina de ensueño</strong>. Desde impresionantes{" "}
              <strong>piscinas infinity</strong> hasta lujosas instalaciones personalizadas,{" "}
              ofrecemos soluciones que transforman tu hogar en un auténtico{" "}
              <strong>oasis de lujo</strong>.
            </p>
            <p className="about-text text-justify">
              Nuestro compromiso inquebrantable con la{" "}
              <strong>sostenibilidad y la innovación tecnológica</strong> garantiza que{" "}
              cada proyecto sea único y eficiente. Si estás buscando{" "}
              <strong>piscinas modernas y sostenibles</strong>, ya sea en{" "}
              <strong>Granada</strong>, <strong>Málaga</strong> o <strong>Jaén</strong>,{" "}
              nuestro equipo de expertos se encargará de hacerlo realidad,{" "}
              combinando <strong>funcionalidad</strong> y <strong>estética</strong> en{" "}
              cada proyecto.
            </p>
            <p className="about-text text-justify">
              No solo construimos piscinas, creamos experiencias. Cada una de nuestras{" "}
              instalaciones está diseñada para ofrecer un{" "}
              <strong>entorno único</strong>, perfecto para reuniones familiares,{" "}
              celebraciones o simplemente disfrutar de momentos de{" "}
              <strong>tranquilidad personal</strong>. Desde el diseño hasta los{" "}
              acabados, garantizamos un resultado impecable que supera tus{" "}
              expectativas.
            </p>
            <p className="about-text text-justify">
              Descubre la diferencia de trabajar con <strong>Piscinas Jiménez Ureña</strong> y{" "}
              da el primer paso hacia la piscina que siempre soñaste.
            </p>
            {showScrollArrow && (
              <div className="scroll-arrow-wrapper" onClick={scrollToGallery}>
                <span className="scroll-text">Ver Fotos</span>
                <span className="scroll-arrow">⬇</span>
              </div>
            )}
          </div>

          <div className="col-md-6 px-0 justify-content-center" ref={galleryRef}>
          <div className="gallery-container">
  {images.map((image, index) => (
    <div
      key={index}
      className="gallery-item"
      onClick={() => handleImageClick(index)}
      role="button"
      aria-label={`Abrir imagen ${index + 1}`}
    >
      <img
        src={image}
        alt={`Imagen ${index + 1}`}
        className="gallery-image"
        loading="lazy"
      />
      <div className="gallery-overlay">
        <i className="gallery-icon fas fa-search-plus" aria-hidden="true"></i>
        <button className="btn btn-primary">Hacer Zoom</button>
      </div>
    </div>
  ))}
</div>

</div>

        </div>
      </div>

      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="modal-overlay"
        contentLabel="Image Gallery"
        ariaHideApp={false}
      >
        <div className="modal-content">
          <Swiper
            initialSlide={activeImageIndex}
            modules={[Navigation, Pagination, Zoom]}
            navigation
            pagination={{ clickable: true }}
            zoom={true}
            spaceBetween={10}
            slidesPerView={1}
            style={{ height: '100%', width: '100%' }}
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="swiper-zoom-container" style={{ height: '100%' }}>
                  <img
                    src={image}
                    alt={`Piscina ${index + 1}`}
                    className="swiper-image"
                    style={{ width: '100%', height: '100%', objectFit: 'fill' }}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <button
            onClick={closeModal}
            className="close-modal-btn"
            style={{ top: `${modalOffsetTop - 35}px`, right: '20px' }}
          >
            Cerrar
          </button>
        </div>
      </Modal>
    </section>
  );
};

export default AboutSection;
